<template>
  <section class="key-word-option">
    <h2 class="title">关键词配置</h2>
    <el-form label-width="80px" :model="form">
      <el-form-item label="名称">
        <el-input v-model="eventName" disabled></el-input>
      </el-form-item>
      <el-form-item label="核心词">
        <el-input type="textarea" :rows="5" v-model="form.keyword" placeholder="词与词之间请用、隔开"></el-input>
      </el-form-item>
      <el-form-item label="同现词">
        <el-input type="textarea" :rows="5" v-model="form.cooccurrenceWord" placeholder="词与词之间请用、隔开"></el-input>
      </el-form-item>
      <el-form-item label="排除词">
        <el-input type="textarea" :rows="5" v-model="form.excludeWord" placeholder="词与词之间请用、隔开"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="goBack">返回</el-button>
        <el-button type="primary" @click="submit">保存</el-button>
      </el-form-item>
    </el-form>
    <!-- <el-table
      :data="tableData"
      border
      style="width: 985px; margin: auto;">
      <el-table-column  :resizable="false"
        type="index"
        label="序号"
        align="center"
        width="60">
      </el-table-column>
      <el-table-column  :resizable="false"
        prop="coreWords"
        label="核心词"
        align="center"
        >
      </el-table-column>
      <el-table-column  :resizable="false"
        prop="homonymWords"
        label="同现词"
        align="center"
        >
      </el-table-column>
      <el-table-column  :resizable="false"
        prop="exclusionWords"
        label="排除词"
        align="center"
        >
      </el-table-column>
      <el-table-column  :resizable="false"
        align="center"
        width="180"
        label="操作">
        <template scope="{ row }">
          <el-button type="text" class="danger" @click="handleRemove(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table> -->
<!--    <el-dialog-->
<!--      title="提示"-->
<!--      :visible.sync="dialogVisible"-->
<!--      width="30%"-->
<!--      :before-close="handleClose">-->
<!--      <span class="prompt"><i class="el-icon-warning"></i>此操作将永久删除该内容，不可恢复，是否继续？</span>-->
<!--      <span slot="footer" class="dialog-footer">-->
<!--        <el-button @click="dialogVisible = false">取 消</el-button>-->
<!--        <el-button type="primary" @click="deleteKeyWord">确 定</el-button>-->
<!--      </span>-->
<!--    </el-dialog>-->
  </section>
</template>

<script>
import { saveEvolutionKeyword, selectAllEvolutionKeyword } from '@/api/eventEvolution'
export default {
  data () {
    return ({
      form: {
        keyword: '',
        cooccurrenceWord: '',
        excludeWord: ''
      },
      eventName: '',
      tableData: [
        {
          id: 1,
          keyWord: '宁波万科'
        },
        {
          id: 2,
          keyWord: '万科地产'
        }
      ],
      dialogVisible: false,
      id: ''
    })
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // async deleteKeyWord () {
    //   try {
    //     const res = await deleteEvolutionKeyword({ id: this.id })
    //     if (res.code === 0) {
    //       this.handleClose()
    //       this.$message.success('删除成功')
    //     } else {
    //       this.$message.error('删除失败')
    //     }
    //     console.log(res)
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    async submit () {
      try {
        const res = await saveEvolutionKeyword({ evolutionId: this.$route.params.id, keyword: this.form.keyword,cooccurrenceWord: this.form.cooccurrenceWord,excludeWord: this.form.excludeWord })
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.viewSelectAllEvolutionKeyword()
        } else {
          this.$message.error(res.msg)
        }
        console.log(res)
      } catch (e) {
        console.log(e)
      }
    },
    // handleRemove (id) {
    //   console.log(id)
    //   this.id = id
    //   this.dialogVisible = true
    // },
    // handleClose () {
    //   this.dialogVisible = false
    //   this.viewSelectAllEvolutionKeyword()
    // },
    async viewSelectAllEvolutionKeyword () {
      const res = await selectAllEvolutionKeyword({ evolutionId: this.$route.params.id })
      this.form = res.data
    }
  },
  mounted () {
    this.viewSelectAllEvolutionKeyword()
    this.eventName = this.$route.params.name
  }
}
</script>

<style lang="scss">
.key-word-option {
  width: 100%;
  min-height: 100%;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(53,64,82,0.06);
  border-radius: 10px;
  padding: 40px 30px;
  .title {
    font-size: 18px;
    color: #333333;
    margin: 0;
  }
  .el-form {
    margin-top: 40px;
    .el-input {
      width: 481px;
    }
    .el-textarea {
      width: 670px;
      // height:238px;
      textarea {
        height: 100%;
      }
    }
  }
  .el-button {
    &.danger {
      color: #FE0000;
      padding: 0;
    }
  }
  .el-dialog {
    .el-dialog__body {
      padding: 10px 20px;
      .prompt {
        height: 28px;
        display: flex;
        align-items: center;
        .el-icon-warning {
          font-size: 28px;
          color: #FF8B35;
          margin-right: 12px;
        }
      }
    }
  }
}
</style>
